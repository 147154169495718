import styled, { css }  from 'styled-components';
import { LikeCounts, CommentCounts } from '../SocialFeeds/styles';

const BoldFont = 'Rubik-Bold';

const OverlaySection = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 60px;
  background-image: url(/images/background-gradient.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const ContentSection = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px 12px;
`;

const PhotoContainer = styled.div`
  width: calc((100% - 105px)/3);
  height: 12vw;
  max-height: 12vw;
  display: inline-block;
  margin: 17px;
  position: relative;
  cursor: pointer;

  @media (max-width: 1200px) and (min-width: 501px) {
    width: calc((100% - 70px)/2);
  }

  @media (max-width: 950px) and (min-width: 768px) {
    height: 15vw;
    max-height: 15vw;
  }

  @media (max-width: 767px) and (min-height: 501px) {
    height: 25vw;
    max-height: none;
  }

  @media (max-width: 500px) {
    width: calc(100% - 20px);
    margin: 10px;
    height: auto;
    max-height: none;
  }
`;

const PhotoContainerV2 = styled.div`
  width: calc((100% - 75px)/4);
  height: 12vw;
  max-height: 12vw;
  display: inline-block;
  margin-right: ${({marginRight}) => marginRight ? marginRight : '0'};
  margin-bottom: 25px;
  position: relative;
  cursor: pointer;

  @media (max-width: 1200px) and (min-width: 501px) {
    width: calc((100% - 70px)/2);
  }

  @media (max-width: 950px) and (min-width: 768px) {
    height: 15vw;
    max-height: 15vw;
  }

  @media (max-width: 767px) and (min-height: 501px) {
    height: 25vw;
    max-height: none;
  }

  @media (max-width: 500px) {
    width: calc(100% - 20px);
    margin: 10px;
    height: auto;
    max-height: none;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  height: inherit;
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-height: 12vw;
    @media (max-width: 950px) and (min-width: 768px) {
      max-height: 15vw;
    }
    
    @media (max-width: 767px) and (min-width: 501px) {
      max-height: none;
    }
    
    @media (max-width: 500px) {
      height: auto;
      max-height: none;
    }
  }
`;

const LikeLink = styled(LikeCounts)`
  font-size: 13px;
  letter-spacing: 0px;
  line-height: 16px;
  font-family: ${BoldFont};
  background-color: transparent;
  > span {
    color: rgb(255,255,255);
  }
  
  > i {
    color: rgb(255,255,255);
  }

  ${({ active }) => active && css`
    > i {
      color: #ef6827;
    }
  `}
`;

const CommentLink = styled(CommentCounts)`
  font-size: 13px;
  letter-spacing: 0px;
  line-height: 16px;
  font-family: ${BoldFont};
  background-color: transparent;
  > span {
    color: rgb(255,255,255);
  }
  > i {
    color: rgb(255,255,255);
  }
`;

const PhotosContainer = styled.div`
  float: left;
  width: 100%;
  padding: 25px 18px;
  background-color: white;
  box-shadow: 1px 1.732px 46px rgba(0,0,0,0.1);

  @media (max-width: 400px) {
    padding: 20px 10px;
  }
`;

const PhotosContainerV2 = styled.div`
  float: left;
  width: 100%;
  // padding: 25px 18px;
  // background-color: white;
  // box-shadow: 1px 1.732px 46px rgba(0,0,0,0.1);

  @media (max-width: 400px) {
    padding: 20px 10px;
  }
`;

const LinkContainer = styled.div`
  width: 100%;
  float: left;
  text-align: center;
  margin-bottom: 18px;

  > a {
    font-size: 14px;
    letter-spacing: 0px;
    color: rgb(21,159,202);
    font-family: ${BoldFont};
    cursor: pointer;

    &:hover, &:active, &:focus {
      color: rgb(21,159,202);
      text-decoration: none;
    }
  }
`;

const Layout = styled.div`
  float: left;
  width: 65%;
  margin-right: 35px;

  @media only screen and (max-width: 767px) and (min-width: 501px)  {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  @media (max-width: 500px) {
    padding: 25px 0;
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

const LayoutV2 = styled.div`
  float: left;
  width: 100%;
  margin-right: 35px;

  @media only screen and (max-width: 767px) and (min-width: 501px)  {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  @media (max-width: 500px) {
    padding: 25px 0;
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

export { ImageContainer, OverlaySection, CommentLink, LikeLink, PhotosContainer, LinkContainer, Layout, ContentSection, PhotoContainer, PhotosContainerV2, PhotoContainerV2, LayoutV2 };
